import { useEffect, useState } from "react";
import { QuoteSolrDto } from "../../api";
import { useQuoteEmail } from "../../hooks/api/mutations/useQuoteEmail";
import EmailDetailModal from "../modals/EmailDetailModal";

const EmailQuotesWrapper = () => {
  const [selectedQuotes, setSelectedQuotes] = useState<QuoteSolrDto[]>([]);

  useEffect(() => {
    const handleShowEmailQuotesModalEvent = (event: Event) => {
      const rows = (event as CustomEvent).detail;
      setSelectedQuotes(rows);
    };

    document.addEventListener(
      "showEmailQuotesModal",
      handleShowEmailQuotesModalEvent,
    );

    return () =>
      document.removeEventListener(
        "showEmailQuotesModal",
        handleShowEmailQuotesModalEvent,
      );
  }, []);

  return (
    <>
      {selectedQuotes?.length ? (
        <EmailDetailModal
          isOpen
          accountCodes={selectedQuotes
            .map((quote) => quote.accountCode)
            .filter((value, index, array) => {
              return array.indexOf(value) === index;
            })}
          idsWithAccounts={selectedQuotes.map((quote) => {
            return { accountCode: quote.accountCode, id: quote.orderNum };
          })}
          typeLabel="Quote"
          useSendEmailHook={useQuoteEmail}
          onClose={() => {
            setSelectedQuotes([]);
          }}
        />
      ) : null}
    </>
  );
};

export default EmailQuotesWrapper;
