import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import "./assets/css/global.css";
import "./assets/css/react-datepicker.css";

import { ScopedCssBaseline, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import { SnackbarProvider } from "notistack";
import React, { ReactElement } from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom/client";
import App from "./App";
import { OpenAPI } from "./api";
import SalesSummary from "./components/SalesSummary/SalesSummary";
import DealsEditSelectedWrapper from "./components/legacy/DealsEditSelectedWrapper";
import EditEolDateWrapper from "./components/legacy/EditEolDateWrapper";
import EditStaffRightsWrapper from "./components/legacy/EditStaffRightsWrapper";
import EmailQuotesWrapper from "./components/legacy/EmailQuotesWrapper";
import ImportExternalLicensesWrapper from "./components/legacy/ImportExternalLicensesWrapper";
import ImportTaxExemptionsWrapper from "./components/legacy/ImportTaxExemptionsWrapper";
import ManageAuthenticatedLinkWrapper from "./components/legacy/ManageAuthenticatedLinkWrapper";
import MoveLinesWrapper from "./components/legacy/MoveLinesWrapper";
import PurchaseAnalysisWrapper from "./components/legacy/PurchaseAnalysisWrapper";
import ReturnTrackingCodesWrapper from "./components/legacy/ReturnTrackingCodesWrapper";
import RmaRequestWrapper from "./components/legacy/RmaRequestWrapper";
import SkuAndLicenseProgramWrapper from "./components/legacy/SkuAndLicenseProgramWrapper";
import StaffChangePasswordWrapper from "./components/legacy/StaffChangePasswordWrapper";
import AccountTransactionsTable from "./components/tables/AccountTransactionsTable";
import BlanketPOsTable from "./components/tables/BlanketPOsTable/BlanketPOsTable";
import ExportBlanketPOButton from "./components/tables/BlanketPOsTable/ExportBlanketPOButton";
import RecentElectronicPosTable from "./components/tables/RecentElectronicPosTable";
import RecentOrdersTable from "./components/tables/RecentOrdersTable";
import RecentQuotesTable from "./components/tables/RecentQuotesTable";
import { SnackbarContent } from "./components/ui";
import { AuthContextProvider, AUTH_COOKIE_KEY } from "./contexts/AuthContext";
import globalStyles from "./globalStyles";
import muiTheme from "./theme";
import { redirectToAuthPage } from "./utils/link";

OpenAPI.BASE = process.env.REACT_APP_API_URL ?? "";

axios.interceptors.response.use(
  (res) => res,
  (err: AxiosError) => {
    if (err.response?.status === 401) {
      Cookies.remove(AUTH_COOKIE_KEY);
      redirectToAuthPage();
    }
    if (err.response?.data) {
      return Promise.reject(err.response.data);
    }
  },
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const renderMuiComponent = (selectorId: string, children: ReactElement) => {
  const element = document.getElementById(selectorId);

  if (element) {
    const root = ReactDOM.createRoot(element);
    root.render(
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <CookiesProvider>
            <AuthContextProvider>
              <ThemeProvider theme={muiTheme}>
                <SnackbarProvider
                  Components={{
                    default: SnackbarContent,
                    error: SnackbarContent,
                    info: SnackbarContent,
                    success: SnackbarContent,
                    warning: SnackbarContent,
                  }}
                  anchorOrigin={{ horizontal: "center", vertical: "top" }}
                  autoHideDuration={5000}
                >
                  {globalStyles}
                  <ScopedCssBaseline>{children}</ScopedCssBaseline>
                </SnackbarProvider>
              </ThemeProvider>
            </AuthContextProvider>
          </CookiesProvider>
          <ReactQueryDevtools initialIsOpen={true} />
        </QueryClientProvider>
      </React.StrictMode>,
    );
  }
};

renderMuiComponent("EmailQuotesModalContainer", <EmailQuotesWrapper />);
renderMuiComponent("optimizeContainer", <PurchaseAnalysisWrapper />);
renderMuiComponent("salesSummaryContainer", <SalesSummary />);
renderMuiComponent(
  "accountTransactionsTableContainer",
  <AccountTransactionsTable />,
);
renderMuiComponent("editEolDateModalContainer", <EditEolDateWrapper />);
renderMuiComponent(
  "skuAndLicenseProgramModalContainer",
  <SkuAndLicenseProgramWrapper />,
);
renderMuiComponent("blanketPOsContainer", <BlanketPOsTable />);
renderMuiComponent("exportBlanketPosButttonWrapper", <ExportBlanketPOButton />);
renderMuiComponent("recentOrdersTableContainer", <RecentOrdersTable />);
renderMuiComponent("editStaffRightsModalContainer", <EditStaffRightsWrapper />);
renderMuiComponent(
  "editSelectedDealsModalContainer",
  <DealsEditSelectedWrapper />,
);
renderMuiComponent("rmaRequestModalContainer", <RmaRequestWrapper />);
renderMuiComponent("moveLinesModalContainer", <MoveLinesWrapper />);
renderMuiComponent(
  "importExternalLicensesWrapper",
  <ImportExternalLicensesWrapper />,
);
renderMuiComponent(
  "manageAuthenticatedLinkModalWrapper",
  <ManageAuthenticatedLinkWrapper />,
);
renderMuiComponent(
  "changeStaffPasswordModalContainer",
  <StaffChangePasswordWrapper />,
);
renderMuiComponent(
  "importTaxExemptionsWrapper",
  <ImportTaxExemptionsWrapper />,
);

renderMuiComponent(
  "returnTrackingCodesModalContainer",
  <ReturnTrackingCodesWrapper />,
);
renderMuiComponent("recentQuotesTableContainer", <RecentQuotesTable />);
renderMuiComponent("pendingEposContainer", <RecentElectronicPosTable />);

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <CookiesProvider>
          <AuthContextProvider>
            <App />
          </AuthContextProvider>
        </CookiesProvider>
        <ReactQueryDevtools initialIsOpen={true} />
      </QueryClientProvider>
    </React.StrictMode>,
  );
}
